<template>
  <l-map
    ref="stationMap"
    :options="mapOptions"
    @ready="onMapReady()"
    @moveend="onBoundsChanged"
    @locationfound="onLocationFound"
  >
    <l-tile-layer :url="tile_url" :attribution="tile_attribution"></l-tile-layer>
    <l-circle color="#ef7d00" fill-color="#ef7d00" :key="circleKey" ref="circle" :lat-lng="pos" :interactive="false" :radius="accuracy" v-if="pos" />
    <l-geojson :options="geojsonOptions" :geojson="geojson"/>
  </l-map>
</template>

<script>
import L from 'leaflet';
import Vue from 'vue';
import { attach_popup } from '../lib/maptools';

  export default {
    name: 'SelectStation',

    props: {
      tile_url: String,
      tile_attribution: String,
      geojson_attribution: String,
      show_osm_tags: Boolean,
      customFetch: {
        type: Function,
        default: () => fetch,
      },
    },
    data: () => ({
      // will be filled in in "created"
      mapOptions: null,
      loadedBounds: null,
      pos: null,
      accuracy: null,
      circleKey: false,
      geojson: null,
      waitingForView: true,
    }),

    computed: {
      geojsonOptions() {
        return {
          onEachFeature: (feature,layer) => {
            attach_popup(feature,
                         layer,
                         this.show_osm_tags,
                         platform => this.$emit("start-acquisition", platform, null),
                         (platform, line) => this.$emit("start-acquisition", platform, line));
          },
          pointToLayer(_feature, latLng) {
            return L.circleMarker(latLng);
          },
          attribution: this.geojson_attribution
        };
      }
    },

    created() {
      // prevent reactiveness of leaflet objects
      const renderer =  L.canvas({ padding: 0.5, tolerance: 20 });
      delete this.$data.mapOptions;
      this.$data.mapOptions = {
        tapTolerance:30,
        renderer:renderer,
        closePopupOnClick:false,
        worldCopyJump:true
      };
    },

    activated() {
      console.log("SelectStation.vue has been activated.");
      this.invalidateMap();
    },

    methods: {
      onBoundsChanged() {
        const b = this.map.getBounds();
        const zoom = this.map.getZoom();
        this.$emit("bounds-changed", this);
        console.log("bounds changed to [",b,"], [",zoom,"]");

        if (zoom >= 16) {

          if (this.loadedBounds == null || !this.loadedBounds.contains(b)) {

            const lb = b.pad(0.5);

            this.customFetch(`/svc/lines_platforms?lat0=${lb.getSouth()}&lon0=${lb.getWest()}&lat1=${lb.getNorth()}&lon1=${lb.getEast()}`).then(
              (resp) => {
                resp.json().then(
                  (js) => {
                    this.geojson = Object.freeze(js);
                    this.loadedBounds = lb;
                  }
                );
            }).catch(err => console.log("Request to /svc/lines_platforms failed: ",err))
          }
        }
        else {
          this.geojson = [];
          this.loadedBounds = null;
        }
      },
      onLocationFound(e) {
        console.log("locationfound");
        this.accuracy = e.accuracy;
        this.pos = e.latlng;
        this.circleKey = !this.circleKey;
        Vue.nextTick(() => {
          this.$refs.circle.mapObject.bringToBack();
        });

        if (this.waitingForView) {
          this.map.setView(e.latlng, this.map.getBoundsZoom(e.bounds));
          this.waitingForView = false;
        }
      },
      onMapReady() {
        this.map = this.$refs.stationMap.mapObject
        if (this.map && this.tile_url) {
          this.setupMap();
        }
      },
      setupMap() {
        this.locate = new L.Control.Locate({
          setView: true
        });

        this.locate.addTo(this.map);

        this.map.locate({
          watch: true
        });
      },
      invalidateMap() {
        if (this.map) {
          Vue.nextTick(() => {
            this.map.invalidateSize();
          });
        }
      },
    }
  }
</script>
