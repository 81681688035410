import en from 'vuetify/lib/locale/de'

export default {
  $vuetify: en,
  uboras: {
    common: {
      ok: "OK",
      save: "Save",
      send: "Send",
      cancel: "Cancel",
      delete: "Delete",
      name: "Name",
      email: "e-mail address",
      comment: "Comment",
      folder: "Folder",
      details: "More information\u2026",
      description: "Description",
      actions: "Actions",
      tag: "Tags | Tag | Tags",
      networkError: "You are offline.",
      start: "Start",
    },
    input: {
      required: "Required.",
      invalidEMail: "Invalid e-mail address."
    },
    contact: {
      subject: "Subject",
      email: "e-mail Address",
      message: "Your message to us"
    },
    locales: {
      de: "German",
      en: "English",
    },
    login: {
      header: "User Sign On",
      user: "User",
      password: "Password",
      login: "Login",
      logout: "Logout",
      loggedOnAs: "Logged on as [{0}]",
      logoutSuccessMsg: "Logout successful. If you want to re-login:"
    },
    survey: {
      survey: "Survey",
      startDate: "Begin Date",
      endDate: "End Date",
      line: "Lines | Line | Lines",
      platform: "Platform",
      endPlatform: "End Platform",
      selectStation: "Select Station",
      startStationControl: "Initiate Bus Station Survey",
      openSurveys: "Open Surveys",
      finishedSurveys: "Finished Surveys",
      resultFromDate: "Result from {startDate}, {startTime} to {endTime}",
      openPrintableTable: "Printable Table",
      getCsvFile: "CSV File",
      currentSurveys: "Current Surveys",
      experiences: "Experiences",
      startAcquisition: "Start Aquisition"
    },
    experience: {
      experience: "Experience",
      result: "Result",
      surveyResults: "Survey Results",
      back: "Back",
      send: "Send",
      takeImage: "Take Image",
      showResultsTooltip: "Show details of this result",
    },
  }
}
