<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-1 font-weight-bold mb-2">
          {{$t('uboras.survey.survey')}}
        </h1>
      </v-col>
    </v-row>

    <!-- currentPageIndex >= surveyType.pages.length means we're past the actual survey pages, at the result page.
         So this template is only rendered while still on a survey page.
         Also check for resultPage here, because result is lazily initialized in mounted(), to avoid null reference -->
    <template v-if="currentPageIndex < surveyType.pages.length &amp;&amp; resultPage">
      <v-row>
        <v-col cols="12">
          <h2 v-if="line"> {{surveyPage.title}}
              {{surveyPage.ref=="line" ? line.tags.name : platform.tags.name}}</h2>
          <h2 v-else> {{surveyPage.title}} </h2>
        </v-col>
      </v-row>

      <v-row v-if="surveyPage.need_line">
        <v-col>
          <v-card>
            <v-card-title>
             {{$tc('uboras.survey.line', 1)}}
            </v-card-title>

            <v-card-text>
              <v-select :items="platform.lines"
                :item-text="[&quot;tags&quot;, &quot;name&quot;]"
                :value="line"
                @input="$emit('line-changed', $event)"
                return-object
                clearable/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" v-for="(sf,findex) in surveyPage.fields" :key="sf.id">
          <v-card>
            <div :class="{'d-flex':sf.image,'flex-no-wrap':sf.image,'justify-space-between':sf.image}">
            <div>

            <v-card-title>
            {{sf.label}}
            </v-card-title>
            <!-- check for resultPage, because result is lazily
                  initialized in mounted() -->
            <v-card-text>
              <v-switch v-if="sf.check" v-model="resultPage.fields[findex].check" :disabled="disabled" />
              <v-radio-group
                v-if="sf.option"
                v-model="resultPage.fields[findex].option"
                row
                :disabled="disabled">
                <v-radio
                  v-for="so in surveyType.option_groups[sf.option]"
                  :key="so.id"
                  :label="so.label"
                  :value="so.id"
                  >
                </v-radio>
              </v-radio-group>
              <br v-if="sf.option &amp;&amp; sf.text"/>
              <v-text-field
                v-if="sf.text == 'simple'"
                v-model="resultPage.fields[findex].text"
                :label="$t('uboras.common.comment')"
                :disabled="disabled">
              </v-text-field>
              <v-textarea
                v-if="sf.text == 'multi'"
                v-model="resultPage.fields[findex].text"
                :label="$t('uboras.common.comment')"
                :disabled="disabled">
              </v-textarea>
            </v-card-text>
            </div>
            <div>
               <v-btn
                 v-if="sf.image"
                 :disabled="disabled"
                 @click="acquireImageField = resultPage.fields[findex]"
                    class="ml-2 mt-3"
                    fab
                    icon
                    height="40px"
                    right
                    width="40px"
                  >
                    <v-icon>mdi-camera</v-icon>
                  </v-btn>
              <v-avatar v-if="sf.image"
                class="ma-3"
                size="125"
                tile
              >
                <v-img :src="resultPage.fields[findex].image" @click="onImageClick(findex)"></v-img>
                <!-- <v-img :src="resultPage.fields[findex].image" @click="onImageClick(findex resultPage.fields[findex].image)"></v-img> -->
              </v-avatar>
            </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <br><br>
      <v-row class="footbar">
        <v-col cols="12" class="d-flex justify-space-between">
          <v-btn v-if="currentPageIndex == 0" @click="onBack">
            &larr;{{$t('uboras.experience.back')}}
          </v-btn>
          <v-btn v-if="currentPageIndex > 0" @click="--currentPageIndex">
            &larr;{{surveyType.pages[currentPageIndex-1].title}}
          </v-btn>
          <v-btn v-if="currentPageIndex+1 < surveyType.pages.length" @click="++currentPageIndex">
            {{surveyType.pages[currentPageIndex+1].title}}&rarr;
          </v-btn>
          <v-btn v-if="currentPageIndex+1 == surveyType.pages.length" @click="++currentPageIndex">
            {{$t('uboras.experience.result')}}&rarr;
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <!-- Show result -->
    <template v-if="currentPageIndex >= surveyType.pages.length &amp;&amp; resultPages.length > 0">
      <ExperienceResult :surveyType="this.surveyType" :expResult="getResult()" />
      <v-row class="footbar">
        <v-col cols="12" class="d-flex justify-space-between">
          <v-btn v-if="currentPageIndex > 1" @click="--currentPageIndex">
            &larr;{{surveyType.pages[currentPageIndex-1].title}}
          </v-btn>
          <v-btn @click="onSend">
            {{$t('uboras.experience.send')}}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <AcquireImage :shown="acquireImageField != null" @input="onImageInput"/>

    <v-dialog v-model="photoEnlarged">
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn @click="photoEnlarged=false; enlargedPhotoUrl = null;">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-img
          :src="enlargedPhotoUrl"
          max-height="480"
          contain
        ></v-img>
        <v-card-actions class="justify-center">
        <v-btn
          @click="removeImage(enlargedPhotoIndex); photoEnlarged = false"
          elevation="2"
          large
          style="margin: 10px 0px 10px 0px"
          ><span style="font-size: 1.3em">{{$t("uboras.common.delete")}}</span></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>

</template>

<script>
import { formatDate } from '../lib/Util';
import AcquireImage from './AcquireImage';
import ExperienceResult from './ExperienceResult';
import OsmId from '../lib/OsmId';

  export default {
    name: 'AcquireExperience',

    model: {
      prop: "line",
      event: "line-changed",
    },

    components: {
      AcquireImage,
      ExperienceResult
    },

    props: {
      surveyType: {
        type: Object,
        default: () => { return {pages:[]} }
      },
      survey_id: {
        type: Number,
        default: null
      },
      platform: Object,
      line: {
        type: Object,
        default: null,
      },
      customFetch: {
        type: Function,
        default: () => fetch,
      },
    },
    data: function() { return {
      currentPageIndex: 0,
      started_time: null,
      acquireImageField: null,
      photoEnlarged: false,
      enlargedPhotoIndex: null,
      enlargedPhotoUrl: null,
    }},

    computed: {
      // a computed getter
      surveyPage: function () {
        return this.surveyType.pages[this.currentPageIndex];
      },
      resultPages() {
        return this.surveyType.pages.map(sp => {
          return {
            id: sp.id,
            ref: sp.ref,
            fields: sp.fields.map(sf => {
              const rf = {
                id: sf.id
              };

              if (sf.option) {
                rf.option = null;
              }
              if (sf.check) {
                rf.check = null;
              }
              if (sf.text) {
                rf.text = null;
              }
              if (sf.image) {
                rf.image = null;
              }
              return rf;
            })
          };
        });
      },
      resultPage: function () {
        return this.resultPages[this.currentPageIndex];
      },
      line_id() {
        if (this.line) {
          return OsmId.fromElement(this.line);
        } else {
          return null;
        }
      },
      platform_id() {
        return OsmId.fromElement(this.platform);
      },
      disabled() {
        //If on a page which requires a bus line to be set(value need_line is true), and no bus line is set,
        //the input elements are set to disabled
        return this.surveyPage.need_line && !this.line;
      },
    },

    mounted: function() {

      console.log("Experience acquisition mounted with line [",this.line_id,"] and platform [",this.platform_id,"]");

      const d = new Date();

      //If a line is specified at the start, then the user directly selected to perform a bus LINE survey instance
      //instead of a bus STATION, thus start at that page
      let busLinePageIndex = this.surveyType.pages.findIndex(p => p.need_line);
      this.currentPageIndex = this.line && busLinePageIndex !== -1 ? busLinePageIndex : 0;

      this.started_time = d;
    },

    methods: {
      formatDate,
      onImageInput(data) {

        if (this.acquireImageField) {
          this.acquireImageField.image = data;
        }
        this.acquireImageField = null;
      },
      onImageClick(findex) {
        let imageUrl = this.resultPage.fields[findex].image;
        if (!imageUrl)
          return;
        this.enlargedPhotoIndex = findex;
        this.enlargedPhotoUrl = this.resultPage.fields[findex].image;
        this.photoEnlarged = true;
      },
      removeImage(findex) {
        if (isNaN(+findex)) //undefined or null
          return;
        this.resultPage.fields[findex].image = null;
      },
      getResult() {
        return {
          line_id: this.line_id,
          platform_id: this.platform_id,
          survey_id: this.survey_id,
          start_millis: this.started_time.getTime(),
          pages: this.resultPages
        };
      },
      onSend() {

        const d = new Date();

        const result = this.getResult();

        result.end_millis = d.getTime();

        console.log("Sending experience [",result,"]");

        this.customFetch("/svc/experience",{
          method:"POST",
          body: JSON.stringify(result)
        }).then(resp => resp.json()).then(
          (ret) => {
            const loc = ret.location;
            console.log("Sent experience with URL [",loc,"]")

            const promises = []

            for(let url in ret.url_replacements) {
              promises.push(

                fetch(url).then(resp => resp.blob()).then(
                  (blob) => {
                    const durl = ret.url_replacements[url];

                    console.log("POSTing attachment [",url,"] to [",durl,"]");
                    return this.customFetch(durl,{
                      method:"POST",
                      body: blob,
                    }).then(resp => resp.json()).then(
                      r=> {
                        console.log("POSTed attachment [",url,"] to [",r,"]");
                        URL.revokeObjectURL(url);
                        return r.location;
                      }
                    )
                  }
                )
              );
            }

            Promise.all(promises).then(
              results => console.log("POSTed attachments with results [",results,"]"));

            this.$emit('sent',loc)
          }
        ).catch(
          (err) => console.log("Sending experience failed",err)
        );


      },
      onBack() {
        this.$emit('sent',null);
      },
      renderCheck(val) {
        if (val == null) {
          return "-";
        }
        else {
          return val ? "\u2611" : "\u2610";
        }
      },
      renderOption(grp,val) {
        if (val == null) {
          return "-";
        }
        else {
          const opts = this.surveyType.option_groups[grp];

          for (let i=0;i<opts.length;++i) {
            if (opts[i].id == val) {
              return opts[i].label;
            }
          }
          return "-";
        }
      },
    }
  }
</script>

<style scoped>
  .footbar {
    background: white;
    position: sticky;
    bottom: 0px;
  }

  #enlargedPhotoDialog {
    background-color: white;
  }
</style>
