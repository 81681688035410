<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-1 font-weight-bold mb-2">
          {{$t('uboras.survey.openSurveys')}}
        </h1>
      </v-col>
    </v-row>

    <v-data-table
      dense
      :headers="headers"
      :items="surveys"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:[`item.start_millis`]="{ item }">
        <span>{{ formatDate(new Date(item.start_millis)) }}</span>
      </template>
      <template v-slot:[`item.end_millis`]="{ item }">
        <span>{{ item.end_millis ? formatDate(new Date(item.end_millis)) : '\u2014' }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
         <v-icon
          @click="openExperiences(item)"
          :aria-label="$t('uboras.survey.experiences')"
        >
          mdi-format-list-bulleted
        </v-icon>
        <v-icon class='ml-2'
          @click="startExperience(item)"
          :aria-label="$t('uboras.survey.startAcquisition')"
        >
          mdi-ray-start-arrow
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { formatDate } from '../lib/Util';
import Vue from 'vue';

  export default {
    name: 'Surveys',

    props: {
      customFetch: {
        type: Function,
        default: () => fetch,
      },
    },
    data: function() { return {
      surveys: [],
      headers: [
        {
          text: this.$t('uboras.common.description'),
          align: 'start',
          value: 'description',
        },
        {
          text: this.$t('uboras.survey.startDate'),
          align: 'start',
          value: 'start_millis',
        },
        {
          text: this.$t('uboras.survey.endDate'),
          align: 'start',
          value: 'end_millis',
        },
        {
          text: this.$t('uboras.common.actions'),
          align: 'start',
          value: 'actions',
        },
      ],
    }},

    activated() {

      console.log("Activated surveys page.");
      Vue.nextTick(() => {
        this.customFetch("/svc/survey/current").then(resp => resp.json()).then(
          ret => this.surveys=ret
        ).catch(
          err => console.log("Fetching current survey failed:",err)
        );
      });
    },

    methods: {
      formatDate,
      openExperiences(item) {
        console.log("Opening experiences for survey [",item,"]");
        this.$emit('openExperiences',item);
      },

      startExperience(item) {
        console.log("Starting experience for survey [",item,"]");
        this.$emit('startExperience',item);
      },
    }
  }
</script>
