<template>
  <v-dialog v-model="shown" style="z-index: 1002;">
    <v-card>
      <v-card-title class="headline">
        {{$t('uboras.login.header')}}
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" :disabled="submitting">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field :label="$t('uboras.login.user')" v-model="user" :rules="[rules.required]" @keyup="onKeyupUser" ref="userInput"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :label="$t('uboras.login.password')" v-model="passwd" type="password" :rules="[rules.required]" @keyup="onKeyupPasswd" ref="passwdInput"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="onSubmit" :disabled="!formValid || submitting">
          {{$t('uboras.login.login')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { makeInputRules } from '../lib/Util';


export default {
  name: 'LoginDialog',

  data: function() { return {
    user: '',
    passwd: '',
    shown: false,
    formValid: false,
    submitting: false,
    loginPromises: [],
    rules: makeInputRules(this),
  }},

  methods: {
    onKeyupUser(event) {
      if (event.key == "Enter") {
        this.$refs.passwdInput.focus();
      }
    },
    onKeyupPasswd(event) {
      if (this.formValid && event.key == "Enter") {
        this.onSubmit();
      }
    },
    onSubmit() {
      console.log("Logging on to [/svc/login] with [",this.user,"].");
      this.submitting = true;
      fetch("/svc/login",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'user':this.user,'passwd':this.passwd})
      }).then(resp => resp.json()).then(
        user => {
          console.log("Successful logon to [/svc/login] as [",user,"].");
          for (let p in this.loginPromises) {
            try {
              this.loginPromises[p].resolve(user);
            }
            catch(err) {
              console.log("Warning Error resolving login promise [",p,"]:",err);
            }
          }
          this.loginPromises = [];
          this.shown = false;
        }
      ).catch(
        err => {
          console.log("Warning: Error logging in:",err);
        }
      ).finally(() => this.submitting=false);
    },
    reloginHandler() {

      const ret = new Promise((resolve,reject) => {
        this.loginPromises.push({resolve,reject});
      });

      if (!this.shown && this.loginPromises.length == 1) {
        // try token refresh
        console.log("Checking refresh token on [/svc/checkLogin].");
        fetch("/svc/checkLogin").then(
          resp => {
            if (resp.status == 401) {
              console.log("Session refresh on [/svc/checkLogin] was unauthenticated, proceeding with ordinary login.");
              this.shown = true;
            }
            else {
              resp.json().then(
                user => {
                  console.log("Successful session refresh on [/svc/checkLogin] as [",user,"].");
                  for (let p in this.loginPromises) {
                    try {
                      this.loginPromises[p].resolve(user);
                    }
                    catch(err) {
                      console.log("Warning Error resolving login promise [",p,"]:",err);
                    }
                  }
                  this.loginPromises = [];
                  this.shown = false;
                },
                err => {
                  console.log("Parsing JSON response to [/svc/checkLogin] failed",err);
                  this.shown = true;
                }
              );
            }
          },
          err => {
            console.log("Call to [/svc/checkLogin] failed",err);
            this.shown = true;
          }
        );
      }
      else {
        this.shown = true;
      }

      return ret;
    }
  }
};
</script>
