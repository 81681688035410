<template>
  <v-dialog v-model="shown" fullscreen style="z-index: 1002;">
    <v-card>
      <v-card-title class="headline">
        {{$t('uboras.experience.takeImage')}}
      </v-card-title>
      <v-card-text>
        <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight" class="d-none"/>
        <video ref="video" style="max-width:100%;max-height:100%;" @loadedmetadata="onVideoMetadataLoaded">
        </video>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onSubmit">
          {{$t('uboras.common.save')}}
        </v-btn>
        <v-btn @click="onCancel">
          {{$t('uboras.common.cancel')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


export default {
  name: 'AcquireImage',

  props: {
    shown: {
      type: Boolean,
      default: false
    },
    max_width: {
      type: Number,
      default: 1024
    },
  },

  data: function() { return {
    //imageCapture: null,
    track: null,
    canvasWidth: 640,
    canvasHeight: 480,
  }},

  watch: {
    shown: function () {

      if (this.shown) {

        console.log("Searching list of camera devices...");
        navigator.mediaDevices.enumerateDevices()
          .then(devices => {

            let device = null;

            for (let i=0;i<devices.length;++i) {
              const d = devices[i];

              if (d.kind=="videoinput" &&
                  (device == null || d.label.toLowerCase().indexOf("back") !=0)) {
                device = d;
              }
            }

            console.log("Opening device [",device,"]");

            navigator.mediaDevices.getUserMedia({
              video: { deviceId: device.deviceId }

            }).then(mediaStream => {
              console.log("Got mediaStream [",mediaStream,"] with tracks [",mediaStream.getVideoTracks(),"]");
              this.track = mediaStream.getVideoTracks()[0];
              this.$refs.video.srcObject = mediaStream;

              const settings = this.track.getSettings();
              console.log("Track setting are [",settings,"]");

              this.canvasWidth = Math.min(this.max_width,settings.width);
              this.canvasHeight = Math.round(settings.height*this.canvasWidth/settings.width);

              //this.imageCapture = new ImageCapture(track);
            });
          })
          .catch(err => {
            console.log("Error finding correct camera",err);
          });
      }
      else {
        if (this.track) {
          this.track.stop();
        }
        this.$refs.video.srcObject = null;
        /*
          this.imageCapture = null;
        */
      }
    }
  },

  methods: {
    onVideoMetadataLoaded() {
      console.log("Video metadata loaded");
      this.$refs.video.play();
    },

    onSubmit() {

      if (this.track) {

        console.log("Capturing image with dimension [",
                    this.canvasWidth,"x",this.canvasHeight,"]");

        const context = this.$refs.canvas.getContext('2d');
        context.drawImage(this.$refs.video,
                          0,0,
                          this.canvasWidth,this.canvasHeight);

        this.$refs.canvas.toBlob(blob => {
            const dataUrl = URL.createObjectURL(blob);
            context.clearRect(0,0,
                              this.canvasWidth,this.canvasHeight);
            console.log("Captured image [",dataUrl,"] with width [",this.canvasWidth,"], type [",blob.type,"] and size [",blob.size,"].");
            this.$emit('input',dataUrl);
          },
          'image/jpeg',0.85);
      }

      /*
      if (this.imageCapture) {

        console.log("Capturing image with width [",this.max_width,"]");

        this.imageCapture.takePhoto({imageWidth: this.max_width})
          .then(blob => {
            const dataUrl = URL.createObjectURL(blob);
            console.log("Captured image [",dataUrl,"] with width [",this.max_width,"], type [",blob.type,"] and size [",blob.size,"].");
            this.$emit('input',dataUrl);
          }).catch(err => {
            console.log("Capturing image failed with width contraint",err);
            console.log("Retrying image capture without constraint...");

            this.imageCapture.takePhoto()
              .then(blob => {
                const dataUrl = URL.createObjectURL(blob);
                console.log("Captured image [",dataUrl,"] with no constraint, type [",blob.type,"] and size [",blob.size,"].");
                this.$emit('input',dataUrl);
              }).catch(err => {
                console.log("Capturing image failed",err);

              });
          });
      }
*/
      else {
        this.$emit('input',null);
      }
    },
    onCancel() {
      this.$emit('input',null);
    },
  }
};
</script>
