import Vue from 'vue';
import { LMap, LTileLayer, LCircle, LMarker, LGeoJson } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

L.Control.Locate = L.Control.extend({
  onAdd(map) {
    const ret=document.createElement("button");
    ret.classList.add("controlButton");
    // text representation of pushpin emoji
    ret.textContent = String.fromCodePoint(0x25CE, 0xFE0E);

    L.DomEvent.disableClickPropagation(ret);
    L.DomEvent.on(ret, "click", L.DomEvent.stop);
    L.DomEvent.on(ret, "click",() => {
      map.stopLocate();
      map.locate(this.options);
      map.waitingForView = !!this.options.setView;
    });
    return ret;
  }
})

export default function() {
  Vue.component('l-map', LMap);
  Vue.component('l-tile-layer', LTileLayer);
  Vue.component('l-circle', LCircle);
  Vue.component('l-geojson', LGeoJson);
  Vue.component('l-marker', LMarker);
}
