<template>
  <Experiences
    :customFetch="app.customFetch"
    :survey_id="app.survey.id"
    :surveyType="app.surveyType"
  />
</template>

<script>
// This component currently only serves to pass props to the Experiences component.
import Experiences from "../components/Experiences.vue";

export default {
  components: {
    Experiences,
  },
  data() {
    return {
      app: this.$root.app
    };
  },
};
</script>
