import i18n from './../plugins/i18n'

function ensure_node(inp){
  if (!(inp instanceof Node)){
    return document.createTextNode(inp.toString());
  } else {
    return inp;
  }
}

function create_element(tag_name, options = undefined){
  const ret=document.createElement(tag_name);

  if(options === undefined) {
    return ret;
  }

  if(options.text !== undefined) {
    ret.textContent = options.text;
  }

  if(options.props !== undefined){
    Object.assign(ret, options.props);
  }

  if(options.children !== undefined){
    for(const i of options.children){
      ret.appendChild(ensure_node(i));
    }
  }

  if(options.attributes !== undefined){
    for (const [k, v] of Object.entries(options.attributes)) {
      ret.setAttribute(k, v);
    }
  }

  if(options.classes !== undefined){
    for(const i of options.classes) {
      ret.classList.add(i);
    }
  }

  if(options.events !== undefined) {
    for (const [k, v] of Object.entries(options.events)) {
      if(typeof(v) === "function"){
        ret.addEventListener(k, v);
      } else {
        ret.addEventListener(k, v.callback, v);
      }
    }
  }

  return ret;
}

function create_lines_list(props, startLineAquisition) {
  return create_element("ul", {
    children: props.lines.map(line => create_element("li", {
      children: [create_element("a", {text: line.tags.name, props: {
        href: "#"
      }, events: {
        click: (e) => {
          startLineAquisition(props, line);
          e.preventDefault();
        }
      }})]
    }))
  });
}

function map_entries(obj, f) {
  return Object.entries(obj).map(([k, v]) => f(k, v));
}

function create_popup(props, show_osm_tags, startPlatformAquisition, startLineAquisition) {

  const children = [];
  children.push(create_element("h3", {text: props.tags.name}));
  if (startPlatformAquisition) {
    children.push(create_element("a", {text: i18n.t("uboras.survey.startStationControl"), props: {
      href: "#"
    }, events: {
      click: (e) => {
        startPlatformAquisition(props);
        e.preventDefault();
      }
    }}));
    children.push(create_element("br"));
    children.push(create_element("br"));
  }
  if (show_osm_tags) {
    children.push(
      create_element("section", {
        children: [
          create_element("h4", { text: i18n.tc("uboras.common.tag", 2)}),
          create_element("ul", {
            children: map_entries(props.tags, (k, v) => {
              return create_element("li", {text: `${k} = ${v}`});
            })
          })
        ]
      })
    );
  }

  let lines_list = create_lines_list(props, startLineAquisition);
  children.push(
    create_element("section", {
      children: [
        create_element("h4", {text: i18n.tc("uboras.survey.line", lines_list.children.length)}),
        lines_list
      ]}));

  return create_element("section", {
    children: children
  });
}

export function attach_popup(feature, layer, show_osm_tags, startPlatformAquisition, startLineAquisition) {
  layer.bindPopup(create_popup(feature.properties, show_osm_tags, startPlatformAquisition, startLineAquisition),
                  { autoPan: false }
                 );
}
