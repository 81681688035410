<template>
  <AcquireExperience
    v-if="!loading && app.platform !== null"
    :line="app.line"
    :platform="app.platform"
    :survey_id="Number.parseInt($route.params.survey_id)"
    :surveyType="app.surveyType"
    :customFetch="app.customFetch"
    @sent="onExperienceSent"
    @line-changed="lineChanged"
    v-model="app.line"
  />
</template>
<script>
import AcquireExperience from "../components/AcquireExperience.vue";
import OsmId from '../lib/OsmId';

// The primary function of this component is to uphold the invariant that app.platform, and app.line correspond to the route.
export default {
  components: {
    AcquireExperience,
  },
  data() {
    return {
      // Begin loading as false in order to avoid loading being true forever after a hot-reload.
      // This does require that we double check whether we have any platform loaded and if we don't, act as if loading were true.
      loading: false,
      app: this.$root.app
    };
  },
   // this callback gets called when we navigate the same route but with different params.
   beforeRouteUpdate(to, from, next) {
     next();
     this.fetchData();
   },
   // This callback gets called when we navigate to this component from a different route,
   // notably also when multiple routes point to the same component and we navigate between those routes.
   // This special property means this callback cannot be easily replaced by similar ones.
   beforeRouteEnter(to, from, next) {
     next(vm => {
       vm.fetchData();
     });
   },
   computed: {
     platform_osmid() {
       return new OsmId(
         this.$route.params.platform_type,
         Number.parseInt(this.$route.params.platform_id)
       );
     },
     line_osmid() {
       return this.$route.meta.has_line ? new OsmId(
         this.$route.params.line_type,
         Number.parseInt(this.$route.params.line_id)
       ) : null;
     },
   },
  methods: {
    fetchData() {
      // check whether we need to fetch the platform and after we have done that set loading to false.
      return this.tryFetchPlatform()
                 .then(() => this.loading = false);
    },
    async tryFetchPlatform() {
      // fetch platform and the corresponding lines when it doesn't exist
      // or its id is not equal to the id in the route.
      if (this.app.platform === null || !OsmId.isEqual(OsmId.fromElement(this.app.platform), this.platform_osmid)) {
        // avoid unnecessarily setting loading to true in order to avoid recreating the AcquireExperience component
        this.loading = true;
        const resp = await this.app.customFetch("/svc/platform?" + new URLSearchParams({
          type: this.$route.params.platform_type,
          id: this.$route.params.platform_id,
        }));
        this.app.platform = await resp.json();
      }
      this.updateLine();
    },
    // this function assumes the platform has been loaded correctly.
    // this function finds the line specified by the route in the platform and assigns it to app.line.
    updateLine() {
      if (this.$route.meta.has_line) {
        this.app.line = this.app.platform.lines.find(line =>
          OsmId.isEqual(OsmId.fromElement(line), this.line_osmid));
      } else {
        this.app.line = null;
      }
    },
    // if the user selects a new line (or none), navigate to the corresponding route.
    lineChanged(new_line) {
      if (new_line !== null) {
        this.$router.push({
          name: "acquire_line",
          params: {
            survey_id: this.$route.params.survey_id,
            platform_type: this.$route.params.platform_type,
            platform_id: this.$route.params.platform_id,
            line_type: new_line.type,
            line_id: new_line.id,
          },
        });
      } else {
        this.$router.push({
          name: "acquire_platform",
          params: {
            survey_id: this.$route.params.survey_id,
            platform_type: this.$route.params.platform_type,
            platform_id: this.$route.params.platform_id,
          },
        });
      }
    },
    // go back to the selection screen after the user has sent the experience.
    onExperienceSent() {
      this.$router.push({
        name: "select",
        params: {
          survey_id: this.$route.params.survey_id,
        },
      });
    },
  },
};
</script>
