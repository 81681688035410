import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from './i18n';

Vue.use(Vuetify);

console.log("Initializing vuetify with i18n locale [",i18n.locale,"].");

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#ef7d00",
        secondary: "#646363",
        accent: "#ffab52", // HSL 31,100,66
      },
      dark: {
        primary: "#000000",
        secondary: "#646363",
        accent: "#323232",
      },
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
    current: i18n.locale
  },
  i18n,
});
