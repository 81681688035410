<template>
  <v-app>
    <v-app-bar
      app
      color="secondary"
      dark
      >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <a href="https://www.uboras.com/">
        <v-img
          alt="Uboras Logo"
          class="shrink d-none d-sm-flex"
          contain
          :src="require('./assets/logo-light.svg')"
          transition="scale-transition"
          />

        <v-img
          alt="Uboras Logo"
          class="shrink d-sm-none d-flex"
          contain
          :src="require('./assets/logo-light-xs.svg')"
          transition="scale-transition"
          />

      </a>

      <v-spacer></v-spacer>

      <span class="mr-2" v-if="survey !== null">{{ survey.description }}</span>

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      style="z-index: 1002;"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title pb-2">
                {{user.gecos}}
              </v-list-item-title>
              <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Uboras Demo App
              </v-list-item-title>
              <v-list-item-subtitle v-if="config">v{{ config.version }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="backToSurveys">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="ml-2">{{$t('uboras.survey.openSurveys')}}</span>
          </v-list-item>
          <v-list-item @click="onLogout">
              <v-icon>mdi-open-in-new</v-icon>
              <span class="ml-2">{{$t('uboras.login.logout')}}</span>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <keep-alive>
        <router-view/>
      </keep-alive>
    </v-main>
    <LoginDialog ref="loginDialog" />
    <v-snackbar v-model="network_error" color="error">
      {{ $t("uboras.common.networkError") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="network_error = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import LoginDialog from './lib/LoginDialog';
import reloginFetch from './lib/ReloginFetch'

export default {
  name: 'App',
  
  components: {
    LoginDialog,
  },
  
  data: () => ({
    drawer: false,
    config: null,
    user: {id:null,name:"\u2014",gecos:"\u2014",email:"\u2014"},
    survey: null,
    surveyType: null,
    platform: null,
    line: null,
    network_error: false,
  }),
  
  created() {
    console.log("App has been created.");
    this.$root.app = this;
  },

  mounted() {

    console.log("App has been mounted.");
    this.$root.reloginHandler = this.$refs.loginDialog.reloginHandler;
    
    this.customFetch("/svc/config2")
      .then((resp) => resp.json())
      .then((cfg) => {
        console.log("Got config [", cfg, "].");
        this.config = cfg.config;
        this.user = cfg.user;
      });
  },
  
  methods: {
    onLogout() {
      fetch("/svc/logout").then(() => {
        location.hash = "";
        location.reload();
      });
    },
    backToSurveys() {
      this.$router.push({ name: "surveys" });
    },
    customFetch(resource, options) {
      return reloginFetch(
        resource,
        Object.assign({
          reloginHandler: this.$root.reloginHandler,
          userCallback: (user) => {
            console.log("Got new user [", user, "].");            
            this.user = user;
          }
        }, options)
      ).catch(e => {
        this.network_error = true;
        throw e;
      });
    },
  },
};
</script>
