<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo-dark.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Uboras Demo App
        </h1>

        <p class="subheading font-weight-regular">
          HSC Uboras Quality in Public Transport<br/>
          &copy;  {{from}}&ndash;{{to}} <a href="http://www.hsctransportconsult.com/" target="_blank">hsc transport consult GmbH</a>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn @click="onStart">{{$t('uboras.common.start')}}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: function() { return {
      from: 2020,
      to: 1900 + new Date().getYear() 
    }},

    methods: {
      onStart() {
        console.log("Start clicked.");
        this.$router.push({ name: "surveys" });
      },
    },
  }
</script>
