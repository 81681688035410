export function formatDate(d) {
  return (d.getYear()+1900).toString().padStart(4,0) + "-" +
    (d.getMonth()+1).toString().padStart(2,0) + "-" +
    d.getDate().toString().padStart(2,0);
}

export function formatTime(d) {
  return d.getHours().toString().padStart(2,0) + ":" +
    d.getMinutes().toString().padStart(2,0) + ":" +
    d.getSeconds().toString().padStart(2,0);
}

export function formatTimestamp(d) {
  return formatDate(d) + " " + formatTime(d);
}

export function parseDate(s) {
  const ret = {};

  if (s.charAt(4) == "-") {
    ret.year=Number(s.substring(0,4));
  }

  if (s.charAt(7) == "-") {
    ret.month=Number(s.substring(5,7));
  }

  if (s.length == 10) {
    ret.day=Number(s.substring(8,10));
  }
  return ret;
}

export function parseTime(s) {
  const ret = {};

  if (s.charAt(2) == ":") {
    ret.hours=Number(s.substring(0,2));
  }

  if (s.length == 5 || s.charAt(5) == ":") {
    ret.minutes=Number(s.substring(3,5));
  }

  if (s.length == 8) {
    ret.seconds=Number(s.substring(6,8));
  }
  return ret;
}

export function makeMap(items) {
  const ret ={};
  for (let i=0;i<items.length;++i) {
    const item=items[i];
    ret[item.id] = item;
  }
  return ret;
}

export function makeInputRules(vm) { return {
  required(value) {
    return !!value || vm.$t('uboras.input.required');
  },
  email(value) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || vm.$t('uboras.input.invalidEMail');
  },
}}

export function supportsWebp() {
  // MSIE
  if (window.navigator.userAgent.indexOf('Trident/') > 0) {
    return false;
  }
  // Safari on Mac OS X 10_x
  if (window.navigator.userAgent.indexOf('Safari/') > 0 &&
      window.navigator.userAgent.indexOf('Chrome/') < 0 &&
      window.navigator.userAgent.indexOf('Mac OS X 10_') > 0) {
    return false;
  }
  return true;
}
