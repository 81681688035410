<template>
  <SelectStation
    :tile_url="this.app.config.tile_url"
    :tile_attribution="app.config.tile_attribution"
    :geojson_attribution="app.config.geojson_attribution"
    :show_osm_tags="app.config.show_osm_tags"
    :customFetch="app.customFetch"
    @start-acquisition="startAquisition"
  />
</template>
<script>
import SelectStation from "../components/SelectStation.vue";

export default {
  components: {
    SelectStation,
  },
  data() {
    return {
      app: this.$root.app
    };
  },
  methods: {
    startAquisition(platform, line) {
      // We set app.platform and app.line so that the AcquireExperiencePage component does not need to refetch them.
      this.app.platform = platform;
      this.app.line = line;

      // depending on whether we have a line or not we navigate to a different route.
      if (line === null) {
        this.$router.push({
          name: "acquire_platform",
          params: {
            survey_id: this.$route.params.survey_id,
            platform_type: platform.type,
            platform_id: platform.id,
          },
        });
      } else {
        this.$router.push({
          name: "acquire_line",
          params: {
            survey_id: this.$route.params.survey_id,
            platform_type: platform.type,
            platform_id: platform.id,
            line_type: line.type,
            line_id: line.id,
          },
        });
      }
    },
  },
};
</script>
