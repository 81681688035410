<template>
  <v-container>
    <v-row>
      <v-col cols="12" v-for="(sp,pindex) in surveyType.pages" :key="sp.id">
        <v-card>
          <v-card-title>
            {{sp.title}}
          </v-card-title>
          <v-card-text>
            <p v-for="(sf,findex) in sp.fields" :key="sf.id">
              {{sf.label}}:
              <span v-if="sf.check">
                {{renderCheck(expResult.pages[pindex].fields[findex].check)}}
              </span>
              <span v-if="sf.option">
                {{renderOption(sf.option,expResult.pages[pindex].fields[findex].option)}}
              </span>
              <v-icon v-if="sf.image &amp;&amp; expResult.pages[pindex].fields[findex].image">
                mdi-camera
              </v-icon>
              <i v-if="sf.text">
                {{expResult.pages[pindex].fields[findex].text}}
              </i>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'ExperienceResult',

    props: {
      surveyType: {
        type: Object,
        default: () => { return {pages:[]} }
      },
      expResult: {
        type: Object,
        default: () => { return {pages:[]} }
      },
    },
    data: function() { return {
    }},

    methods: {
      renderCheck(val) {
        if (val == null) {
          return "-";
        }
        else {
          return val ? "\u2611" : "\u2610";
        }
      },
      renderOption(grp,val) {
        if (val == null) {
          return "-";
        }
        else {
          const opts = this.surveyType.option_groups[grp];

          for (let i=0;i<opts.length;++i) {
            if (opts[i].id == val) {
              return opts[i].label;
            }
          }
          return "-";
        }
      },
    }
  }
</script>
