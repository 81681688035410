export default function(url,options) {
  if (options.reloginHandler) {
    return new Promise( (resolve,reject) => {

      fetch(url,options).then(
        (resp) => {
          if (resp.status == 401) {
            console.log("Trying to login fetching URL [",url,"].");

            options.reloginHandler().then(
              (user) => {

                if (options.userCallback) {
                  options.userCallback(user);
                }
                console.log("Re-fetching URL [",url,"] after successfuly login of [",user,"].");
                fetch(url,options).then(resolve,reject);
              },
              (err) => {
                console.log("Error during login fetching URL [",url,"]:",err);
                reject(err);
              }
            );
          }
          else {
            resolve(resp);
          }
        },
        (err) => {
          console.log("Fetchinfg URL [",url,"] failed:",err);
          reject(err);
        }
      )
    });
  }
  else {
    return fetch(url,options);
  }
}
