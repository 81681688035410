import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import leaflet from './plugins/leaflet';
import routes from './routes';

Vue.config.productionTip = false

Vue.use(VueRouter);

// register leaflet components
leaflet();

const router = new VueRouter({ routes });

const vm = new Vue({
  i18n,
  vuetify,
  render: h => h("keep-alive", [h("router-view")]),
  router
});

vm.$mount('#app');
