<template>
  <Surveys
    :customFetch="app.customFetch"
    @startExperience="startExperience"
    @openExperiences="openExperiences"
  />
</template>

<script>
import Surveys from "../components/Surveys.vue";

export default {
  components: {
    Surveys,
  },
  data() {
    return {
      app: this.$root.app
    };
  },
  methods: {
    act(survey, start_or_open) {
      // set app.survey so that SurveyPage only needs to fetch the surveyType.
      this.app.survey = survey;
      this.$router.push({
        name: start_or_open? "select" : "experiences",
        params: { survey_id: survey.id }
      });
    },
    startExperience(survey) {
      this.act(survey, true);
    },
    openExperiences(survey) {
      this.act(survey, false);
    },
  },
};
</script>
