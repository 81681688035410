<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-1 font-weight-bold mb-2">
          {{$t('uboras.experience.surveyResults')}}
        </h1>
      </v-col>
    </v-row>

    <v-data-table
      dense
      :headers="headers"
      :items="experiences"
      :sort-by.sync="defaultSortBy"
      :sort-desc.sync="defaultSortDesc"
      item-key="id"
      elevation=2
    >
      <template v-slot:[`item.start_millis`]="{ item }">
        <span>{{ formatTimestamp(new Date(item.start_millis)) }}</span>
      </template>
      <template v-slot:[`item.end_millis`]="{ item }">
        <span>{{ item.end_millis ? formatTimestamp(new Date(item.end_millis)) : '\u2014' }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="openDetailPopup(item)">
          mdi-format-list-text
        </v-icon>
      </template>
      <!--
      <template v-slot:footer>
        Test
      </template>
      -->
    </v-data-table>

    <v-btn elevation="5" style="margin: 5px 0 0 10px" @click="openPrintableTablePopup()">
            <v-icon style="margin-right: 10px;">mdi-printer</v-icon>
            {{$t('uboras.survey.openPrintableTable')}}
    </v-btn>
    <v-btn elevation="5" style="margin: 5px 0 0 10px" @click="openCsvLink()">
            <v-icon style="margin-right: 10px;">mdi-microsoft-excel</v-icon>
            {{$t('uboras.survey.getCsvFile')}}
    </v-btn>

    <!-- <v-dialog v-if="this.selectedExperience !== undefined" fullscreen style="z-index: 1002;"> -->

    <v-dialog fullscreen v-model="detailShown">
      <div id="resultsDialog">
        <v-toolbar>
          <h2> {{
            $t('uboras.survey.resultFromDate',
              {
                startDate: selectedExperience.startDate,
                startTime: selectedExperience.startTime,
                endTime: selectedExperience.endTime
              })
            }} </h2>
          <v-spacer></v-spacer>
          <v-btn @click="detailShown=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <span> <strong>{{$t('uboras.survey.platform') + ": "}}</strong> {{selectedExperience.platform}} </span>
          <br>
          <span> <strong>{{$t('uboras.survey.line') + ": "}}</strong> {{ selectedExperience.line ? selectedExperience.line : "—"}} </span>
          <ExperienceResult :surveyType="surveyType" :expResult="selectedExperience.result"/>
        </v-container>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { formatTimestamp, formatDate, formatTime } from '../lib/Util';
import ExperienceResult from './ExperienceResult'

  export default {
    name: 'Experiences',

    props: {
      survey_id: {
        type: Number,
        default: null,
      },
      surveyType: {
        type: Object,
        default: () => { return {pages:[]} }
      },
      customFetch: {
        type: Function,
        default: () => fetch,
      },
    },
    components: {
      ExperienceResult
    },
    data: function() { return {
      experiences: [],
      headers: [
         {
          text: this.$tc('uboras.survey.line', 1),
          align: 'start',
          value: 'line_name'
        },
        {
          text: this.$t('uboras.survey.platform'),
          align: 'start',
          value: 'platform_name',
        },
        {
          text: this.$t('uboras.survey.endPlatform'),
          align: 'start',
          value: 'end_platform_name'
        },
        {
          text: this.$t('uboras.survey.startDate'),
          align: 'start',
          value: 'start_millis',
        },
        {
          text: this.$t('uboras.survey.endDate'),
          align: 'start',
          value: 'end_millis',
        },
        {
          text: this.$t('uboras.common.actions'),
          align: 'start',
          value: 'actions',
        },

      ],
      defaultSortBy: "platform_name",
      defaultSortDesc: true,
      selectedExperience: {result: undefined, platform: undefined, line: undefined, startDate: undefined},
      detailShown: false,
    }},

    mounted: function() {

      this.customFetch(`/svc/survey/${this.survey_id}/experience/mine`).then(resp => resp.json()).then(
        ret => this.experiences=ret
      ).catch(
        err => console.log("Fetching experiences failed:",err)
      );
    },

    methods: {
      formatTimestamp,
      openDetailPopup(item) {
        console.log(`Opening details for experience [${item}]`);

        this.customFetch(`/svc/experience/${item.id}`).then(resp => resp.json()).then(
          (res) => {
            console.log("Received experience details:", res)
            this.selectedExperience = { result: res,
                                      platform: item.platform_name,
                                      line: item.line_name,
                                      startDate: formatDate(new Date(item.start_millis) ),
                                      startTime: formatTime(new Date(item.start_millis) ),
                                      endTime: formatTime(new Date(item.end_millis) ) };
            this.detailShown = true;
          }
        ).catch(
          (err) => console.log(`Fetching experience with id ${item.survey_id} failed: ${err}`)
        );
      },
      openPrintableTablePopup() {
        console.log("Opening printable table - Survey ID: ", this.survey_id);
        window.open(window.location.href.split("/")[0] + `/svc/survey/${this.survey_id}/table`, "_blank");
      },
      openCsvLink() {
        console.log("Getting CSV for survey - ID: ", this.survey_id);
        window.open(window.location.href.split("/")[0] + `/svc/survey/${this.survey_id}/csv`);
      }
    }
  }
</script>

<style scoped>
  #resultsDialog {
    background-color: white;
  }

</style>
