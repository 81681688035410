import de from 'vuetify/lib/locale/de'

export default {
  $vuetify: de,
  uboras: {
    common: {
      ok: "OK",
      save: "Speichern",
      send: "Senden",
      cancel: "Abbrechen",
      delete: "Löschen",
      name: "Name",
      email: "E-Mail Adresse",
      comment: "Kommentar",
      folder: "Verzeichnis",
      details: "Mehr Informationen\u2026",
      description: "Beschreibung",
      actions: "Aktionen",
      tag: "Tags | Tag | Tags",
      networkError: "Sie sind offline.",
      start: "Start",
    },
    input: {
      required: "Mussfeld.",
      invalidEMail: "Ungültige e-mail Adresse.",
    },
    contact: {
      subject: "Betreff",
      email: "E-Mail Adresse",
      message: "Ihre Nachricht an uns"
    },
    locales: {
      de: "Deutsch",
      en: "Englisch",
    },
    login: {
      header: "Benutzeranmeldung",
      user: "Benutzer",
      password: "Passwort",
      login: "Anmelden",
      logout: "Abmelden",
      loggedOnAs: "Angemeldet als [{0}]",
      logoutSuccessMsg: "Logout erfolgreich. Falls Sie sich neu einloggen wollen:"
    },
    survey: {
      survey: "Erhebung",
      startDate: "Beginndatum",
      endDate: "Enddatum",
      line: "Linien | Linie | Linien",
      platform: "Haltestelle",
      endPlatform: "Endhaltestelle",
      selectStation: "Haltestelle suchen",
      startStationControl: "Haltestelle kontrollieren",
      openSurveys: "Offene Erhebungen",
      finishedSurveys: "Abgeschlossene Erhebungen",
      resultFromDate: "Resultat vom {startDate}, {startTime} bis {endTime}",
      openPrintableTable: "Druckbare Tabelle",
      getCsvFile: "CSV-Datei",
      currentSurveys: "Aktuelle Erhebungen",
      experiences: "Kontrollen",
      startAcquisition: "Kontrolle starten"
    },
    experience: {
      experience: "Aufnahme",
      result: "Resultat",
      surveyResults: "Erhebungs-Resultate",
      back: "Zurück",
      send: "Absenden",
      takeImage: "Bild aufnehmen",
      showResultsTooltip: "Details dieses Resultats anzeigen",
    },
  }
}
