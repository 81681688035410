<template>
  <keep-alive>
    <router-view v-if="!loading" />
  </keep-alive>
</template>

<script>
// The purpose of this component is to uphold the invariant that app.survey, and app.surveyType correspond to the route.
export default {
  data() {
    return {
      app: this.$root.app,
      // The same tricks with loading as in AcquireExperiencePage do not apply here because we will nearly always have to load something.
      // This is because the route here doesn't change often and it is unlikely that the surveyType will already have been fetched since SelectStation doesn't fetch the surveyType even though it fetches the survey.
      loading: true,
    };
  },
   beforeRouteUpdate(to, from, next) {
     next();
     this.fetchData();
   },
   // set survey and surveyType to null if we navigate away from this component in order to prevent the survey description on the app bar from showing if we aren't in a survey.
   beforeRouteLeave(to, from, next) {
     this.app.survey = null;
     this.app.surveyType = null;
     next();
   },
   beforeRouteEnter(to, from, next) {
     next(vm => {
       vm.fetchData();
     });
   },
  methods: {
    fetchData() {
      this.loading = true;
      // ensure the survey has been fetched before we attempt to load the surveyType.
      this.fetchSurvey()
          .then(() => this.fetchSurveyType())
          .then(() => this.loading = false);
    },
    fetchSurvey() {
      if (
           this.app.survey === null
        || this.app.survey.id !== Number.parseInt(this.$route.params.survey_id)
      ) {
        return this.app
                   .customFetch("/svc/survey/"+this.$route.params.survey_id)
                   .then(r => r.json())
                   .then(s => this.app.survey = s);
      }
      return Promise.resolve(this.app.survey);
    },
    fetchSurveyType() {
      if (
        this.app.surveyType === null ||
        this.app.surveyType.id !== this.app.survey.type_id
      ) {
        return this.app
          .customFetch("/svc/survey_type/" + this.app.survey.type_id)
          .then((r) => r.json())
          .then(st => this.app.surveyType = st);
      }
      return Promise.resolve(this.app.surveyType);
    },
  },
};
</script>
