const OsmId = Object.freeze(class {
  static valid_types = Object.freeze([
    "node",
    "way",
    "relation",
  ]);

  constructor(type, id) {
    if (!OsmId.valid_types.includes(type)) {
      throw new RangeError(`The argument type is ${type} not one of "node", "way", or "relation".`);
    }
    if (!Number.isInteger(id)) {
      throw new TypeError("The argument id is not an integer.");
    }
    // openstreetmap id's can be zero as demonstrated by https://www.openstreetmap.org/way/0
    if (id < 0) {
      throw new RangeError("The argument id is not positive.");
    }
    this.type = type;
    this.id = id;
    Object.freeze(this);
  }

  static fromElement(ele) {
    return new OsmId(ele.type, ele.id);
  }

  static isEqual(lhs, rhs) {
    if (!(lhs instanceof OsmId) || !(rhs instanceof OsmId)) {
      return false;
    }
    return lhs.type === rhs.type && lhs.id === rhs.id;
  }
});
export default OsmId;
