import App from './App.vue';
import SurveysPage from './pages/SurveysPage.vue';
import SurveyPage from './pages/SurveyPage.vue';
import SelectStationPage from './pages/SelectStationPage.vue';
import AcquireExperiencePage from './pages/AcquireExperiencePage.vue';
import ExperiencesPage from './pages/ExperiencesPage.vue';
import HelloWorld from './components/HelloWorld.vue';

export default [
  {
    path: "/",
    component: App,
    children: [
      {
        path: "",
        redirect: "start",
      },
      {
        name: "start",
        path: "start",
        component: HelloWorld,
      },
      {
        name: "surveys",
        path: "surveys",
        component: SurveysPage,
      },
      {
        name: "survey",
        path: "survey/:survey_id",
        component: SurveyPage,
        children: [
          {
            name: "select",
            path: "select",
            component: SelectStationPage,
          },
          {
            name: "acquire_platform",
            path: "acquire/platform/:platform_type/:platform_id",
            component: AcquireExperiencePage,
            meta: {
              has_line: false,
            },
          },
          {
            name: "acquire_line",
            path: "acquire/platform/:platform_type/:platform_id/line/:line_type/:line_id",
            component: AcquireExperiencePage,
            meta: {
              has_line: true,
            },
          },
          {
            name: "experiences",
            path: "experiences",
            component: ExperiencesPage,
          },
        ]
      }
    ]
  }
];
